<template>
  <router-view :key="$route.path" />
</template>

<route>
{
"meta": {
"label": "訂單銷售報表"
}
}
</route>

<script>
export default {
  name: 'OrderSaleReport',
  layout: 'manage'
}
</script>

